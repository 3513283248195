/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'

const Filters = ({ tags, handleTotal, handleClick }) => (
  <Styled.div sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: ['80%', '100%', '100%'] }}>
    {Object.keys(tags).map(tag => {
      const total = handleTotal(tag)

      return (
        <button
          className={tags[tag] ? 'active' : ''}
          sx={{
            border: 'none',
            padding: ['2px 5px', '3px 6px', '3px 6px'],
            backgroundColor: tags[tag] ? 'text' : 'background',
            color: tags[tag] ? 'background' : 'text',
            cursor: 'pointer',
            textTransform: 'uppercase',
            fontWeight: 400,
            mr: [1, 2, 2],
            mb: 1,
            ':hover': {
              fontWeight: [400, 800, 800],
              '&.active': {
                fontWeight: 400
              }
            }
          }}
          key={`tag-${tag}`}
          onClick={() => handleClick(tag)}
        >
          {tag} {tags[tag] ? <small>({total})</small> : null}
        </button>
      )
    })}
  </Styled.div>
)

export default Filters
