/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Styled, jsx } from 'theme-ui'
import { graphql, Link } from 'gatsby'
//
import Layout from '../components/layout'
import Seo from '../components/seo'
import Footer from '../components/footer'
import { ImageFixed } from '../utils/image'
import Filters from '../components/filters'
import Search from '../components/search'

const defaultTags = {
  Postcard: false,
  'Framed Poster': false,
  Poster: false,
  'T-Shirt': false,
  Backpack: false,
  Bag: false,
  Blanket: false,
  'Cropped Hoodie': false,
  'Water Bottle': false,
  'iPhone Case': false
}
const defaultSubTags = { Birthday: false, Holidays: false, Halloween: false, Valentine: false, Others: false }

const StorePage = ({ data, location, pageContext }) => {
  const { products: fetchedProducts } = data
  const [searchActive, setSearchActive] = useState(false)
  const [tags, setTags] = useState(defaultTags)
  const [subTags, setSubTags] = useState(defaultSubTags)
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const { allProducts } = pageContext
    const newProducts = fetchedProducts?.edges
      ?.map(edge => edge.node)
      ?.filter(product => Object.prototype.hasOwnProperty.call(allProducts, product?.external_id))
      ?.map(product => {
        for (let i = 0; i < Object.keys(defaultTags).length; i++) {
          if (/Framed Poster/i.test(product.name)) {
            product.tagName = 'Framed Poster'
            break
          } else if (/Poster$/i.test(product.name)) {
            product.tagName = 'Poster'
            break
          } else if (/Postcard$/i.test(product.name)) {
            product.tagName = 'Postcard'
            if (/^Birthday /i.test(product.name)) {
              product.subTagName = 'Birthday'
            } else if (/^Holidays /i.test(product.name)) {
              product.subTagName = 'Holidays'
            } else if (/^Halloween /i.test(product.name)) {
              product.subTagName = 'Halloween'
            } else if (/^Valentine /i.test(product.name)) {
              product.subTagName = 'Valentine'
            } else {
              product.subTagName = 'Others'
            }

            break
          } else if (/T-Shirt$/i.test(product.name)) {
            product.tagName = 'T-Shirt'
            break
          } else if (/Backpack$/i.test(product.name)) {
            product.tagName = 'Backpack'
            break
          } else if (/Bag$/i.test(product.name)) {
            product.tagName = 'Bag'
            break
          } else if (/Blanket$/i.test(product.name)) {
            product.tagName = 'Blanket'
            break
          } else if (/Cropped Hoodie$/i.test(product.name)) {
            product.tagName = 'Cropped Hoodie'
            break
          } else if (/Water Bottle$/i.test(product.name)) {
            product.tagName = 'Water Bottle'
            break
          } else if (/iPhone Case$/i.test(product.name)) {
            product.tagName = 'iPhone Case'
            break
          } else {
            product.tagName = ''
          }
        }
        product.title = product.name
        product.to = `/shop/${product.slug}`
        return product
      })

    setProducts(newProducts)
    setFilteredProducts(newProducts)
    setLoaded(true)
  }, [fetchedProducts, pageContext])

  useEffect(() => {
    if (loaded) {
      const enabledTags = Object.keys(defaultTags).filter(key => tags[key])
      if (enabledTags.length > 0) {
        let newProducts = products.filter(product =>
          enabledTags.some(tag => tag.toLowerCase() === product?.tagName?.toLowerCase())
        )

        const enabledSubTags = Object.keys(defaultSubTags).filter(key => subTags[key])

        if (enabledSubTags.length > 0) {
          newProducts = newProducts.filter(product =>
            enabledSubTags.some(tag => tag.toLowerCase() === product?.subTagName?.toLowerCase())
          )
        }

        setFilteredProducts(newProducts)
      } else {
        setFilteredProducts(products)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, subTags])

  useEffect(() => {
    const enabledTags = location?.search
      ?.replace('?tags=', '')
      ?.split('|')?.[0]
      ?.split(',')
      ?.filter(tag => tag?.length)
      ?.map(tag => tag.replace('%20', ' '))
      ?.filter(tag => Object.keys(defaultTags).includes(tag))

    const newTags = { ...defaultTags }

    if (enabledTags?.length > 0) {
      Object.keys(tags).forEach(tag => {
        newTags[tag] = enabledTags.includes(tag)
      })
    }

    setTags(newTags)

    const enabledSubTags = location?.search
      ?.replace('?tags=', '')
      ?.split('|')?.[1]
      ?.split(',')
      ?.filter(tag => tag?.length)
      ?.map(tag => tag.replace('%20', ' '))
      ?.filter(tag => Object.keys(defaultSubTags).includes(tag))

    const newSubTags = { ...defaultSubTags }

    if (enabledSubTags?.length > 0) {
      Object.keys(subTags).forEach(tag => {
        newSubTags[tag] = enabledSubTags.includes(tag)
      })
    }

    setSubTags(newSubTags)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleTagClick = tag => {
    const newTags = { ...tags }
    newTags[tag] = !tags[tag]

    const enabledTags = Object.keys(newTags).filter(key => newTags[key])
    if (enabledTags.length > 0) {
      window.history.pushState('', document.title, `${location.pathname}?tags=${enabledTags.join(',')}`)
    } else {
      window.history.pushState('', document.title, `${location.pathname}`)
    }

    setTags(newTags)
  }

  const handleSubTagClick = tag => {
    const newTags = { ...subTags }
    newTags[tag] = !subTags[tag]

    const enabledTags = Object.keys(tags).filter(key => tags[key])
    const enabledSubTags = Object.keys(newTags).filter(key => newTags[key])

    if (enabledTags.length > 0) {
      window.history.pushState(
        '',
        document.title,
        `${location.pathname}?tags=${enabledTags.join(',')}|${enabledSubTags.join(',')}`
      )
    } else {
      window.history.pushState('', document.title, `${location.pathname}`)
    }

    setSubTags(newTags)
  }

  return (
    <Layout location={location}>
      <Seo title="Shop" description="My Shop, Purchase my artworks on gumroad" cardType="summary" />

      <div
        sx={
          searchActive
            ? {
                filter: 'blur(5px)',
                opacity: '0.95'
              }
            : {}
        }
      >
        <Styled.h2 sx={{ mb: 1, fontWeight: 400 }}>Shop</Styled.h2>

        <div sx={{ mt: 4, mb: 4 }}>
          <Filters
            key="filters-tags"
            tags={tags}
            handleClick={handleTagClick}
            handleTotal={tag =>
              filteredProducts.filter(product => product?.tagName?.toLowerCase() === tag.toLowerCase()).length
            }
          />

          {Object.keys(tags)
            .filter(tag => tags[tag])
            .includes('Postcard') ? (
            <div sx={{ mt: 2 }}>
              <Filters
                key="filters-subtags"
                tags={subTags}
                handleClick={handleSubTagClick}
                handleTotal={tag =>
                  filteredProducts.filter(product => product?.subTagName?.toLowerCase() === tag.toLowerCase()).length
                }
                isSubTags
              />
            </div>
          ) : null}
        </div>

        <Styled.div
          className="product-grid"
          sx={{
            mb: 4,
            pb: 4,
            gap: 1,
            display: 'grid',
            minHeight: '70vh',
            width: filteredProducts.length < 4 ? ['100%', '100%', '100%', '1024px'] : '100%',
            gridTemplateColumns: [
              'repeat(auto-fit, minmax(100%, 2fr))',
              'repeat(auto-fit, minmax(260px, 2fr))',
              'repeat(auto-fit, minmax(320px, 1fr))'
            ]
          }}
        >
          {filteredProducts.map((product, idx) => (
            <Styled.div key={product.id} id={`p-${idx}`} sx={{ padding: '0.25rem', width: ['100%', '100%', '320px'] }}>
              <Link to={product.to} sx={{ textDecoration: 'none' }} state={{ id: `p-${idx}` }}>
                <Styled.div style={{ width: ['100%', '260px', '320px'], maxWidth: ['100%', '260px', '320px'] }}>
                  <ImageFixed
                    image={product?.productImage?.childImageSharp}
                    style={{ width: '100%', backgroundColor: 'transparent' }}
                    alt={product.name}
                  />
                </Styled.div>
                <Styled.h3 sx={{ marginBlockStart: '0.725rem', fontWeight: 400, fontSize: '0.925rem' }}>
                  {product.name}
                </Styled.h3>
              </Link>
            </Styled.div>
          ))}
        </Styled.div>
        <Footer />
      </div>
      <Search
        handleActive={active => setSearchActive(active)}
        handleSearch={regex =>
          filteredProducts?.filter(item => item?.name?.match(regex) || item?.tagName?.match(regex)) || []
        }
      />
    </Layout>
  )
}

export const pageStoreQuery = graphql`
  query {
    products: allPrintfulProduct {
      edges {
        node {
          id
          external_id
          name
          productImage {
            childImageSharp {
              fixed(width: 320, toFormat: WEBP) {
                ...FragmentImageSharpFixed
              }
            }
          }
          slug
          variants {
            id
            name
            price: retail_price
          }
        }
      }
    }
  }
`

export default StorePage
