/** @jsx jsx */
import React from 'react'
import { css, useColorMode, Input, Flex, Box, jsx, IconButton, Close } from 'theme-ui'
import { Link } from 'gatsby'
//
import { SearchIcon } from './icons'

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

const SearchItem = ({ idx, title, to }) => (
  <Box sx={{ minWidth: '300px', minHeight: ['50px'], textAlign: 'center' }}>
    <Link
      to={to}
      sx={{
        color: 'text',
        textDecoration: 'none',
        variant: 'styles.navlink',
        p: 2,
        lineHeight: 1.2,
        fontWeight: 500,
        ':before': { content: '"' + idx + '. "', fontSize: '.7rem' },
        ':hover': { ':before': { content: '"→ "' } }
      }}
    >
      <span>{title}</span>
    </Link>
  </Box>
)

const Search = ({ handleSearch, handleActive }) => {
  const [colorMode] = useColorMode()
  const [active, setActive] = React.useState(false)

  const [searchTerm, setSearchTerm] = React.useState('')
  const [results, setResults] = React.useState([])

  const inputRef = React.useRef()

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  React.useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
      const regex = new RegExp(debouncedSearchTerm, 'gi')
      const filtered = handleSearch(regex)
      setResults(filtered)
    } else {
      setResults([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  React.useEffect(() => {
    if (active && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
    handleActive(active)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const handleKeyPress = e => {
    if (e.keyCode === 27) {
      setSearchTerm('')
      setActive(false)
    }
  }

  const total = results?.length || 0

  return (
    <>
      {active ? (
        <Flex
          sx={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            background: colorMode === 'default' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)',
            flexDirection: 'column'
          }}
          css={css({
            svg: {
              width: '42px',
              height: '42px'
            }
          })}
        >
          <Box sx={{ width: ['80%', '66%', '33%'] }}>
            <Flex sx={{ alignItems: 'center' }}>
              <Input
                ref={inputRef}
                sx={{
                  borderRadius: '0',
                  border: 'none',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: 'currentcolor',
                  fontSize: ['1.2rem', '2rem', '3rem'],
                  ':focus': {
                    outline: 'none'
                  }
                }}
                value={searchTerm}
                onChange={e => setSearchTerm(e?.target?.value || '')}
                onKeyUp={handleKeyPress}
                placeholder="Type to search..."
              />
              <Close
                sx={{
                  cursor: 'pointer',
                  width: '3rem',
                  marginTop: '1rem'
                }}
                onClick={() => setActive(false)}
              />
            </Flex>
          </Box>
          <Box
            sx={{
              flexDirection: 'row',
              minHeight: '12rem',
              mt: '1rem',
              maxWidth: ['80%', '70%', '66%'],
              justifyContent: 'center'
            }}
          >
            {total ? (
              <Flex
                sx={{
                  mt: '2rem',
                  display: 'grid',
                  gridTemplateRows: 'repeat(4, 52px)',
                  gridTemplateColumns: [
                    'repeat(1, 1fr)',
                    `repeat(${total > 1 ? 2 : total}, 1fr)`,
                    `repeat(${total > 2 ? 3 : total}, 1fr)`
                  ],
                  gridAutoFlow: 'row',
                  fontSize: '1rem',
                  justifyContent: 'center'
                }}
              >
                {results.map((result, idx) => (
                  <SearchItem key={`result-${result.id}`} idx={idx + 1} {...result} />
                ))}
              </Flex>
            ) : (
              <Box sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                {searchTerm.length ? <span>No results found</span> : null}
              </Box>
            )}
          </Box>
        </Flex>
      ) : (
        <Box sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}>
          <IconButton
            onClick={() => {
              setSearchTerm('')
              setActive(true)
            }}
            sx={{
              cursor: 'pointer'
            }}
          >
            <SearchIcon fill={colorMode === 'default' ? '#000' : '#fff'} />
          </IconButton>
        </Box>
      )}
    </>
  )
}

export default Search
